
//---------------------
//a-b테스트
//---------------------
const abRouter = [
    {
        path: "/a-b/dateconfirm",
        name: "ab-dateconfirm",
        component: () => import("../views/a-b/DateConfirmView.vue"),
    },
    {
        path: "/a-b/prevention",
        name: "ab-prevention",
        component: () => import("../views/a-b/PreventionView.vue"),
    },    
    {
        path: "/reels-kre",
        name: "reels-kre",
        component: () => import("../views/a-b/ReelsNotionLinkView.vue"),
    },          
    {
        path: "/miso/move-a",
        name: "miso-move-n",
        component: () => import("../views/a-b/MisoMove-AView.vue"),
    },   
    {
        path: "/miso/move-b",
        name: "miso-move-b",
        component: () => import("../views/a-b/MisoMove-BView.vue"),
    },   
    {
        path: "/ticket/price-b",
        name: "ticket-price-b",
        component: () => import("../views/order/ticket/TicketPrice-B.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },        

    //전세대출
    {
        path: "/analyze/loan-amount",
        name: "loan-amount",
        component: () => import("../views/a-b/AnalyzeLoanAmountView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    }, 
    {
        path: "/analyze/loan-amount-b",
        name: "loan-amount-b",
        component: () => import("../views/a-b/AnalyzeLoanAmountView-B.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    }, 

    // 전세대출
    {
        path: "/loan/gov/possible/analyze",
        name: "loan-gov-possible-analyze",
        component: () => import("../views/a-b/LoanPossibleGovAnalyzeView-A.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/loan/gov/possible/analyze-b",
        name: "loan-gov-possible-analyze-b",
        component: () => import("../views/a-b/LoanPossibleGovAnalyzeView-B.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
];

export default abRouter;
